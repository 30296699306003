import { SxProps } from '@mui/material';
import React from 'react';
import { BridgeToken, TokenType } from 'src/core/type';

import IconERC20Token from './icons/tokens/IconERC20';
import IconEth from './icons/tokens/IconEth';

const TokenLogo: React.FC<{
  token: BridgeToken;
  sx?: SxProps;
}> = ({ sx: _sx, token }) => {
  const sx = Object.assign({ height: '32px', width: '32px' }, _sx);

  if (token.type === TokenType.ERC20) {
    return <IconERC20Token sx={sx} />;
  }

  return <IconEth sx={sx} />;
};

export default TokenLogo;
