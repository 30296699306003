import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Stack, useTheme } from '@mui/material';
import React from 'react';

const SwitchTransferDirection: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <Stack flexDirection="row" justifyContent="center" pb={1} pt={0.75}>
      <ArrowDownwardIcon
        onClick={onClick}
        sx={{
          transform: 'rotate(270deg)',
          color: 'white',
          background: theme.colors.functional.container.dark,
          height: '40px',
          width: '40px',
          borderRadius: '50%',
          padding: '7px',
          cursor: 'pointer',
          ':hover': {
            background: theme.colors.functional.container.primary,
            color: theme.colors.schema.secondary,
          },
        }}
      />
    </Stack>
  );
};

export default SwitchTransferDirection;
