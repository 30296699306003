import type { Signer } from '@ethersproject/abstract-signer';
import type { Provider, TransactionResponse } from '@ethersproject/providers';

import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import { WethAbi } from './abis';
import { BaseContract } from './base';
import { callMethod } from './utils';

class Weth extends BaseContract {
  public symbol: Promise<string> = Promise.resolve('eth');
  public decimals: Promise<number> = Promise.resolve(18);

  constructor(address: string, signerOrProvider: Signer | Provider, abi = WethAbi) {
    super(address, signerOrProvider, abi);
    this.symbol = this.contract.symbol();
    // this.decimals = this.contract.decimals();
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.totalSupply() as Promise<BigNumber>;
  }

  balanceOf(account: string): Promise<BigNumber> {
    return this.contract.balanceOf(account) as Promise<BigNumber>;
  }

  transfer(recipient: string, amount: BigNumberish): Promise<TransactionResponse> {
    return callMethod(this.contract, 'transfer', [recipient, amount]);
  }

  allowance(owner: string, spender: string): Promise<BigNumber> {
    return this.contract.allowance(owner, spender) as Promise<BigNumber>;
  }

  approve(spender: string, amount: BigNumberish): Promise<TransactionResponse> {
    return callMethod(this.contract, 'approve', [spender, amount]);
  }

  transferFrom(
    sender: string,
    recipient: string,
    amount: BigNumberish
  ): Promise<TransactionResponse> {
    return callMethod(this.contract, 'transferFro', [sender, recipient, amount]);
  }

  deposit(amount: BigNumber): Promise<TransactionResponse> {
    const override = { value: BigNumber.from(amount).toString() };

    return callMethod(this.contract, 'deposit', [], override);
  }

  withdraw(amount: BigNumber): Promise<TransactionResponse> {
    return callMethod(this.contract, 'withdraw', [amount]);
  }

  async displayedBalanceOf(account: string): Promise<string> {
    const balance = await this.balanceOf(account);

    return formatUnits(balance, await this.decimals);
  }

  async displayedTotalSupply(): Promise<string> {
    const supply = await this.totalSupply();

    return Number(formatUnits(supply, await this.decimals)).toFixed(0);
  }
}

export { Weth };
