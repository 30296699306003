import { JsonRpcProvider } from '@ethersproject/providers';
import { useMemo } from 'react';

export const useStaticProvider = () => {
  return useMemo(() => {
    const network = window.appConfig.network;
    const provider = new JsonRpcProvider(network.rpcUrls.default.http[0], {
      chainId: network.id,
      name: network.name,
    });

    return provider;
  }, []);
};
