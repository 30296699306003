import { Box, CssBaseline } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout';
import Disclaimer from './pages/Legal/Disclaimer';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import Transfer from './pages/Transfer';
import { inputGlobalStyles } from './theme/globalStyle';

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      {inputGlobalStyles}
      <Layout>
        <Routes>
          <Route
            element={
              <Box
                sx={{
                  mt: 10,
                  flexBasis: '552px',
                }}
              >
                <Transfer />
              </Box>
            }
            path="/transfer"
          />
          <Route
            element={
              <Box
                sx={{
                  overflow: 'scroll',
                }}
              >
                <Disclaimer />
              </Box>
            }
            path="/legal-disclaimer"
          />
          <Route
            element={
              <Box
                sx={{
                  overflow: 'scroll',
                }}
              >
                <PrivacyPolicy />
              </Box>
            }
            path="/privacy-policy"
          />
          <Route element={<Navigate to="/transfer" />} path="*" />
        </Routes>
      </Layout>
    </>
  );
};

export default App;
