export type { Address } from 'abitype';

export type Side = 'l1' | 'l2';

export enum TxType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export interface DepositData {
  message: string;
  blockNumber: number;
  depositHash: string;
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  tokenL1Address: string;
  timestamp: string;
}

export interface WithdrawData {
  blockNumber: number;
  message: string;
  withdrawalHash: string;
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  tokenL1Address: string;
  timestamp: string;
}

export enum TokenType {
  NATIVE = 'native',
  ERC20 = 'erc20',
}

export type BridgeToken = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  type: TokenType;
};

export const ETH: BridgeToken = {
  address: '',
  name: 'eth',
  symbol: 'ETH',
  decimals: 18,
  type: TokenType.NATIVE,
};
