import { Box, Stack, StackProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTokenBalance } from 'src/contexts/TokenBalanceContext';
import { BridgeToken } from 'src/core/type';

import SwitchTransferDirection from './SwitchTransferDirection';

const eth = window.appConfig.tokens.eth;
const weth = window.appConfig.tokens.weth;

const Direction: React.FC<
  { from: BridgeToken; setToken: (token: BridgeToken) => void } & StackProps
> = ({ from, setToken, ...props }) => {
  const theme = useTheme();
  const direction: 'deposit' | 'withdraw' = from === eth ? 'deposit' : 'withdraw';
  const to: BridgeToken = direction === 'deposit' ? weth : eth;
  const [, ethBalance] = useTokenBalance(eth);
  const [, wethBalance] = useTokenBalance(weth);

  return (
    <Stack alignItems="center" flexDirection="row" justifyContent="space-around" px={5} {...props}>
      <Box textAlign="center">
        <Typography variant="h3">
          {direction === 'deposit' ? ethBalance : wethBalance} {from.symbol}
        </Typography>
        <Typography color={theme.colors.functional.text.lint} mt={0.5} variant="subtitle1">
          {from.symbol} balance
        </Typography>
      </Box>
      <SwitchTransferDirection onClick={() => setToken(from === eth ? to : eth)} />
      <Box textAlign="center">
        <Typography variant="h3">
          {direction === 'deposit' ? wethBalance : ethBalance} {to.symbol}
        </Typography>
        <Typography color={theme.colors.functional.text.lint} mt={0.5} variant="subtitle1">
          {to.symbol} balance
        </Typography>
      </Box>
    </Stack>
  );
};

export default Direction;
