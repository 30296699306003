import { Box, Container, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CurrencyWithAmount from 'src/components/CurrencyWithAmount';
import EnsureNetworkButton from 'src/components/EnsureNetworkButton';
import { BridgeToken } from 'src/core/type';
import useAmountBN from 'src/hooks/useAmountBn';

import DepositButton from './DepositButton';
import Direction from './Direction';
import WithdrawButton from './WithdrawButton';

const Transfer: React.FC = () => {
  const theme = useTheme();
  const [selectedToken, setToken] = useState<BridgeToken>(window.appConfig.tokens.eth);
  const [{ amount: amountRaw }, setAmountRaw] = useState<{
    amount: string;
    valid: boolean;
  }>({ amount: '', valid: true });
  const amountBN = useAmountBN(amountRaw, selectedToken);
  const { eth } = window.appConfig.tokens;

  return (
    <Container
      sx={{
        background: theme.colors.functional.container.default,
        padding: '24px',
        border: '1px solid rgba(255,255,255,0.04)',
        borderRadius: '16px',
        boxShadow: '0px 0px 40px 0px rgba(26,26,37,0.20)',
      }}
    >
      <Direction from={selectedToken} mb={5} setToken={setToken} />
      <CurrencyWithAmount
        amount={amountRaw}
        onAmountOrIdChange={setAmountRaw}
        selectedToken={selectedToken}
      />
      <Box mt={5}>
        <EnsureNetworkButton fullWidth targetChainId={window.appConfig.network.id}>
          {selectedToken === eth ? (
            <DepositButton amount={amountBN} />
          ) : (
            <WithdrawButton amount={amountBN} />
          )}
        </EnsureNetworkButton>
      </Box>
    </Container>
  );
};

export default Transfer;
