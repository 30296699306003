import { BigNumber } from 'ethers';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Weth } from 'src/core/weth';
import { useAccount } from 'wagmi';

import { Address, BridgeToken } from '../core/type';
import { formatDisplayUnits } from '../core/utils';
import { useStaticProvider } from '../hooks/useStaticProvider';

export type TokenKey = `l1${Address}` | `l2${string}`;

interface Context {
  tokenBalances: { eth: BigNumber; weth: BigNumber };
  fetchBalance: () => Promise<void>;
}

export const ERC20BalanceOf = {
  inputs: [
    {
      internalType: 'address',
      name: 'account',
      type: 'address',
    },
  ],
  name: 'balanceOf',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256',
    },
  ],
  stateMutability: 'view',
  type: 'function',
};

export const TokenBalanceContext = createContext<Context>({
  tokenBalances: {
    eth: BigNumber.from(0),
    weth: BigNumber.from(0),
  },
  async fetchBalance() {},
});

export const useTokenBalances = () => {
  return useContext(TokenBalanceContext);
};

export const TokenBalanceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const account = useAccount();
  const provider = useStaticProvider();
  const [tokenBalances, setTokenBalances] = useState<{
    eth: BigNumber;
    weth: BigNumber;
  }>({
    eth: BigNumber.from(0),
    weth: BigNumber.from(0),
  });

  const fetchBalance = useCallback(async () => {
    if (!account.isConnected || !account.address) {
      return;
    }

    const { weth } = window.appConfig.tokens;
    const contract = new Weth(weth.address, provider);

    try {
      const [ethBalance, wethBalance] = await Promise.all([
        provider.getBalance(account.address),
        contract.balanceOf(account.address),
      ]);
      const tokenBalances = {
        eth: ethBalance,
        weth: wethBalance,
      };

      console.log('ethBalance', ethBalance.toString(), wethBalance.toString());

      setTokenBalances(tokenBalances);
    } catch (e) {
      console.error(e);
    }
  }, [account.address, account.isConnected, provider]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <TokenBalanceContext.Provider value={{ tokenBalances, fetchBalance }}>
      {children}
    </TokenBalanceContext.Provider>
  );
};

export const useTokenBalance = (token: BridgeToken): [BigNumber, string] => {
  const { tokenBalances } = useTokenBalances();
  const { eth } = window.appConfig.tokens;
  const balance = token === eth ? tokenBalances.eth : tokenBalances.weth;

  return useMemo(() => [balance, formatDisplayUnits(balance, token.decimals, 3)], [balance, token]);
};
