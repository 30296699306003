import { SvgIcon, SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Svg = styled(SvgIcon)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '117.5px',
    height: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '235px',
    height: '40px',
  },
}));

export default function IconLogo({ ...props }: SvgIconProps) {
  return (
    <Svg height="40px" viewBox="0 0 235 40" width="235px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="ETH-to-WETH" transform="translate(-120.000000, -30.000000)">
          <g id="logo" transform="translate(120.000000, 30.000000)">
            <rect height="40" id="矩形" width="235" x="0" y="0"></rect>
            <path
              d="M22.3342142,39.9667531 C22.6299335,39.9671825 22.9228609,39.9088883 23.1962455,39.7951065 C23.4694154,39.6813248 23.7176736,39.5144305 23.9266313,39.3041394 L41.5355647,21.6010407 C41.7447372,21.3907712 41.910529,21.1411854 42.0237055,20.8665116 C42.136882,20.5918377 42.1952957,20.2974302 42.1952957,20.000108 C42.1952957,19.7027857 42.136882,19.4083782 42.0237055,19.1336827 C41.910529,18.8590089 41.7447372,18.6094447 41.5355647,18.39924 L23.9266313,0.696162835 C23.7176736,0.4856947 23.4694154,0.318806911 23.1962455,0.205089304 C22.9228609,0.0913723437 22.6299335,0.0330679506 22.3342142,0.0335242745 L22.3342142,39.9667531 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
            ></path>
            <path
              d="M19.8617263,32.516962 C19.2855998,32.516962 18.7094734,32.3802944 18.2700608,32.1013456 L0.65911089,21.0033735 C-0.21970363,20.4494486 -0.21970363,19.5508968 0.65911089,18.9969935 L18.2687078,7.89906459 C18.7081205,7.62210216 19.2842469,7.48329708 19.8603733,7.48329708 L19.8617263,32.516962 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              opacity="0.5"
            ></path>
            <path
              d="M19.8617263,25.0330604 C19.2855998,25.0330604 18.7094734,24.8963928 18.2700608,24.6180917 L0.65911089,13.5200764 C-0.21970363,12.9661731 -0.21970363,12.0675997 0.65911089,11.5130271 L18.2687078,0.415771832 C18.7053931,0.13676672 19.2842469,0 19.8617263,0 L19.8617263,25.0330604 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              opacity="0.5"
            ></path>
            <path
              d="M19.8617263,40 C19.2855998,40 18.7094734,39.8633324 18.2700608,39.5843836 L0.65911089,28.4871024 C-0.21970363,27.9324434 -0.21970363,27.0338484 0.65911089,26.4800531 L18.2687078,15.382081 C18.7081205,15.1051401 19.2842469,14.9670044 19.8603733,14.9670044 L19.8617263,40 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              opacity="0.5"
            ></path>
            <path
              d="M49.0563263,33.6657905 L59.4402173,6.33630382 L66.7863821,6.33630382 L77.1702731,33.6657905 L70.0773059,33.6657905 L67.6284411,27.4701917 L58.5539185,27.4701917 L56.1475755,33.6657905 L49.0563263,33.6657905 Z M59.4825243,22.4199669 L66.7000501,22.4199669 L63.0704558,12.9147663 L59.4825243,22.4199669 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="形状"
              opacity="0.5"
            ></path>
            <polygon
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              opacity="0.5"
              points="79.5413962 33.6657905 79.5413962 6.33630382 86.3373556 6.33630382 86.3373556 28.4035473 99.1726885 28.4035473 99.1726885 33.6657905"
            ></polygon>
            <path
              d="M134.969323,33.6657905 L145.353214,6.33630382 L152.699164,6.33630382 L163.083055,33.6657905 L155.990732,33.6657905 L153.542082,27.4701917 L144.466915,27.4701917 L142.061002,33.6657905 L134.969323,33.6657905 Z M145.395306,22.4199669 L152.613476,22.4199669 L148.983453,12.9147663 L145.395306,22.4199669 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="形状"
            ></path>
            <polygon
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              points="167.762807 33.6657905 167.762807 23.607874 158.00944 6.33630382 165.10069 6.33630382 171.178719 17.6674097 177.259111 6.33630382 184.350145 6.33630382 174.600859 23.607874 174.600859 33.6657905"
            ></polygon>
            <polygon
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              points="186.626776 33.6657905 186.626776 6.33630382 206.676843 6.33630382 206.676843 11.6018071 193.42252 11.6018071 193.42252 17.3309656 205.115136 17.3309656 205.115136 22.677757 193.42252 22.677757 193.42252 28.4070018 206.676843 28.4070018 206.676843 33.669029"
            ></polygon>
            <path
              d="M208.973875,33.6657905 L208.973875,6.33630382 L222.566868,6.33630382 C225.773177,6.33630382 228.137643,7.1851544 229.658117,8.88289874 C231.176443,10.5806215 231.93668,12.8580049 231.93668,15.7150707 C231.93668,17.554578 231.472806,19.1670616 230.542912,20.5524999 C229.615165,21.9378519 228.348104,22.9707395 226.743875,23.651055 C227.119699,23.9397194 227.441833,24.291428 227.697393,24.6904198 C227.998051,25.199091 228.260054,25.7312959 228.476958,26.2816368 L231.685415,33.6670859 L224.592018,33.6670859 L221.553218,26.7076166 C221.299806,26.1149584 220.975524,25.683581 220.58252,25.4132684 C220.187369,25.1429558 219.624708,25.0084473 218.894537,25.0097427 L215.769834,25.0097427 L215.769834,33.6670859 L208.973875,33.6657905 Z M215.769834,19.7462689 L220.790834,19.7462689 C222.113732,19.7462689 223.148857,19.4139269 223.894061,18.749243 C224.639265,18.084559 225.01294,17.0731539 225.01294,15.7150707 C225.01294,12.9715276 223.705075,11.5993026 221.087197,11.5983958 L215.769834,11.5983958 L215.769834,19.7462689 Z"
              fill="#6667AB"
              fillRule="nonzero"
              id="形状"
            ></path>
            <polygon
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              points="132.652533 28.4037632 119.820637 28.4037632 119.820637 6.33643336 119.772316 6.33643336 119.772316 6.32343591 112.970344 6.32343591 112.970344 33.6768016 119.772316 33.6768016 119.772316 33.6657905 132.652533 33.6657905"
            ></polygon>
            <polygon
              fill="#6667AB"
              fillRule="nonzero"
              id="路径"
              opacity="0.5"
              points="95.7131811 11.5983958 103.826455 11.5983958 103.826455 33.6657905 110.517613 33.6657905 110.517613 6.33630382 95.7131811 6.33630382"
            ></polygon>
          </g>
        </g>
      </g>
    </Svg>
  );
}
