import { Stack, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import { BridgeToken } from 'src/core/type';

import TokenLogo from './TokenLogo';

const CurrencyWithAmount: React.FC<{
  selectedToken: BridgeToken;
  onAmountOrIdChange: (value: { amount: string; valid: boolean }) => void;
  amount: string;
  errorMsg?: string;
}> = ({ amount, errorMsg, onAmountOrIdChange, selectedToken }) => {
  const theme = useTheme();

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <Stack
              alignItems="center"
              flexDirection="row"
              sx={{
                pl: '8px',
                height: '58.8px',
                color: '#111111',
                cursor: 'pointer',
              }}
            >
              <TokenLogo sx={{ height: '28px', width: '28px' }} token={selectedToken} />
              <Typography color="#111111" fontSize="18px" ml={1} mr={0.5} whiteSpace="nowrap">
                {selectedToken?.symbol || 'Select a token'}
              </Typography>
            </Stack>
          ),
        }}
        fullWidth
        onChange={e =>
          onAmountOrIdChange({ amount: e.target.value, valid: e.target.validity.valid })
        }
        placeholder="Enter amount"
        sx={{
          flex: 1,
          '& div.MuiInputBase-formControl': {
            background: 'white',
          },
          '& input.MuiInputBase-input': {
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          },
        }}
        type="number"
        value={amount}
      />
      {errorMsg && (
        <Typography color={theme.colors.schema.failure} mt={1.25} variant="body1">
          {errorMsg}
        </Typography>
      )}
    </>
  );
};

export default CurrencyWithAmount;
