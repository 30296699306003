import { TransactionReceipt } from '@ethersproject/abstract-provider';
import LoadingButton from '@mui/lab/LoadingButton';
import { BigNumber } from 'ethers';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useTokenBalance, useTokenBalances } from 'src/contexts/TokenBalanceContext';
import { Weth } from 'src/core/weth';
import useAlertTransaction from 'src/hooks/useAlertTransaction';
import { useAccount, useSigner } from 'wagmi';

const WithdrawButton: React.FC<{ amount: BigNumber }> = ({ amount }) => {
  const signer = useSigner();
  const { address } = useAccount();
  const { alertConfirmed, alertFailed } = useAlertTransaction();
  const eth = window.appConfig.tokens.weth;
  const { fetchBalance } = useTokenBalances();
  const { isLoading, mutate: withdraw } = useMutation<TransactionReceipt | undefined, any, void>({
    mutationFn: async () => {
      if (!signer.data || !signer.isSuccess || !address) {
        return;
      }

      const bridge = new Weth(eth.address, signer.data);
      const txResponse = await bridge.withdraw(amount);
      const receipt = await txResponse.wait();

      return receipt;
    },
    onSuccess(receipt) {
      if (!receipt) {
        return;
      }

      const hash = receipt.transactionHash;

      fetchBalance();
      alertConfirmed(hash);
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });
  const [balance] = useTokenBalance(window.appConfig.tokens.weth);

  const isDisabled = useMemo(() => {
    return !signer || amount.lte(0) || amount.gte(balance);
  }, [amount, balance, signer]);

  return (
    <LoadingButton
      disabled={isDisabled}
      fullWidth
      loading={isLoading}
      onClick={() => withdraw()}
      variant="contained"
    >
      Withdraw
    </LoadingButton>
  );
};

export default WithdrawButton;
